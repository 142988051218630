import React, { useState, useEffect } from "react";
import { withFirebase } from "../../utils/Firebase";
import Select from "../atoms/Select";
import countryData from "../../data/countryData.json";
import CountryISOCodes from "../../data/countryISOcodes.json";
import getCountryFlag from "../../scripts/getCountryFlag";
import { acosh } from "core-js/fn/math";

const MinistrySelect = ({
  firebase,
  selectedMinistry,
  setSelectedMinistry,
  setSelectedMinistryData = () => {},
}) => {
  const { currentUser } = firebase ? firebase.auth : { currentUser: null };
  const [ministries, setMinistries] = useState([]);

  useEffect(() => {
    if (currentUser && ministries.length === 0) {
      getMinistries();
    }
    if (ministries && ministries.length === 1 && !selectedMinistry) {
      setSelectedMinistry(ministries[0].ministryId);
      setSelectedMinistryData(ministries[0]);
    }
  }, [currentUser, firebase, ministries.length]);

  const getMinistries = async () => {
    const { regions } = await firebase
      .user(currentUser.uid)
      .get()
      .then((data) => data.data());

    const isAdmin = regions.indexOf("all") !== -1;

    if (isAdmin) {
      await firebase
        .ministries()
        .get()
        .then((querySnapshot) => {
          let array = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            console.log(doc.id, data)
            if (data.bibles || doc.id === 'undefined' || doc.id === undefined) {
              console.log(doc.id, 'ministry undefined or reportData')
              return
            }
            // doc.data() is never undefined for query doc snapshots
            array.push(data);
          });
          setMinistries(array);
        })
        .catch((err) => console.log(err));
      return;
    }
    await firebase
      .ministries()
      .where("facilitators", "array-contains", currentUser.uid)
      .get()
      .then((querySnapshot) => {
        let array = [];
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          array.push(doc.data());
        });
        setMinistries(array);
      })
      .catch((err) => console.log(err));
  };
  if (ministries.length < 1) return null;
  return (
    <div
      style={{
        marginTop: 30,
        maxWidth: 400,
        margin: "30px auto",
      }}
    >
      <Select
        labelName="Ministry"
        options={ministries.sort((a, b) => {
          console.log("A", a.country, a);
          console.log("B",b.country, b);
          const aCountry = countryData[a.country].NAME;
          const bCountry = countryData[b.country].NAME;
          return aCountry > bCountry ? 1 : -1;
        }).map((m) => {
          const { ministryId, country, name } = m;
          return {
            value: ministryId,
            label: `${getCountryFlag(CountryISOCodes[country])} ${name}`,
          };
        })}
        label="Select Country"
        value={selectedMinistry}
        setValue={(value) => {
          setSelectedMinistry(value);
          setSelectedMinistryData(
            ministries.find((m) => m.ministryId === value)
          );
        }}
      />
    </div>
  );
};

export default withFirebase(MinistrySelect);
